<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <a-modal
        okText="确定"
        @ok="onout"
        :closable="false"
        :maskClosable="false"
        :visible="logvisible"
        :cancel-button-props="{ props: { disabled: true } }"
      >
        登录已失效，请前往重新登陆
      </a-modal>
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>基础配置</a-breadcrumb-item>
            <a-breadcrumb-item>角色管理</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div v-if="Id == ''" class="Enter-top-right">
          <a-input-group style="width: 300px" compact>
            <a-select style="width: 90px" v-model="value" default-value="name">
              <a-select-option value="name"> 名称 </a-select-option>
              <a-select-option value="code"> 编号 </a-select-option>
              <a-select-option value="state"> 状态 </a-select-option>
            </a-select>
            <a-select
              v-if="value == 'state'"
              @change="onSearch"
              style="width: 210px"
            >
              <a-select-option value="0"> 启用 </a-select-option>
              <a-select-option value="1"> 禁用 </a-select-option>
            </a-select>
            <a-input-search
              v-else
              placeholder="请输入搜索内容"
              style="width: 210px"
              v-model="key"
              @search="onSearch"
            />
          </a-input-group>
          <a-button
            style="margin: 0 15px"
            type="primary"
            @click.prevent="showDrawer"
          >
            新增角色
          </a-button>
          <a-button type="danger" @click.prevent="onDetele"> 删除 </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="
              () => {
                this.Id = ''
                this.visible = false
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div>
            <div v-if="!visible">
              <a-table
                :row-selection="rowSelection"
                rowKey="id"
                :pagination="false"
                :columns="columns"
                :data-source="positionlist"
                :rowClassName="rowClassName"
              >
                <span slot="status" slot-scope="text, record">
                  <a-switch
                    :checked="text == 0 ? true : false"
                    default-checked
                    @change="onchangestate(record)"
                  />
                </span>
                <template slot="action" slot-scope="text, record">
                  <a @click="thisEdit(record)">编辑</a> &nbsp;|&nbsp;
                  <a @click="onEdit(record)">管理权限</a>
                </template>
              </a-table>
              <a-pagination
                v-model="params.current"
                :page-size="params.pageSize"
                show-quick-jumper
                :default-current="1"
                :total="totals"
                @change="onpagesize"
              />
            </div>
            <div style="height: 100%" v-else>
              <div class="edit-title">修改用户角色</div>
              <div class="edit-main">
                <div class="edit-main-top">
                  <div class="edit-main-name">
                    <span style="color: red; margin-right: 5px">*</span>
                    <span style="font-size: 15px">角色名称</span>
                  </div>
                  <a-input disabled v-model="form.name" type="text" />
                </div>
                <div class="edit-main-bottom">
                  <div style="width: 35%">
                    <div class="edit-main-name">
                      <span style="color: red; margin-right: 5px">*</span>
                      <span style="font-size: 15px">操作权限</span>
                    </div>
                    <div class="main-bottom-main">
                      <a-tree
                        v-model="checkedKeys"
                        checkable
                        :blockNode="true"
                        :tree-data="treeData"
                        @check="onSelect"
                      />
                      <div class="main-bottom-btn">
                        <a-button @click="onSavekey" type="primary">
                          保存
                        </a-button>
                      </div>
                    </div>
                  </div>
                  <div style="width: 62%">
                    <div class="edit-main-name">
                      <span style="color: red; margin-right: 5px">*</span>
                      <span style="font-size: 15px">PC端角色权限</span>
                    </div>
                    <div class="main-bottom-main">
                      <a-table
                        :row-selection="rowSelection"
                        rowKey="id"
                        :pagination="false"
                        :columns="column"
                        :data-source="userlist"
                        :rowClassName="rowClassName"
                      >
                        <template slot="action" slot-scope="text, record">
                          <a-popconfirm
                            title="你确认要删除该模块么?"
                            ok-text="是"
                            cancel-text="否"
                            @confirm="ondetailuser(record)"
                            @cancel="cancel"
                          >
                            <a>删除</a>
                          </a-popconfirm>
                        </template>
                      </a-table>
                      <a-pagination
                        v-model="current"
                        :page-size="params.pageSize"
                        show-quick-jumper
                        :default-current="2"
                        :total="total"
                        @change="onpagesizeuser"
                      />
                      <div class="main-bottom-btn">
                        <a-button
                          @click="
                            () => {
                              this.visibles = true
                            }
                          "
                          style="margin-right: 50px"
                          type="primary"
                        >
                          邀请
                        </a-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a-modal
              :visible="visibles"
              okText="确定"
              cancelText="取消"
              title="新增角色"
              @ok="handleOk"
              @cancel="onClose"
            >
              <a-form-model
                v-if="this.visible == true"
                :model="form"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="员工">
                  <a-select
                    show-search
                    :filter-option="filterOption"
                    v-model="userId"
                  >
                    <a-select-option v-for="item in Userlist" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-form-model>
              <a-form-model
                v-else
                ref="ruleForm"
                :rules="rules"
                :model="form"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item ref="name" prop="name" label="名称">
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="form.name"
                  />
                </a-form-model-item>
                <a-form-model-item ref="code" prop="code" label="编号">
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="form.code"
                  />
                </a-form-model-item>
              </a-form-model>
            </a-modal>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '编号',
    dataIndex: 'code',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const column = [
  {
    title: '姓名',
    dataIndex: 'name',
  },
  {
    title: '用户名',
    dataIndex: 'username',
  },
  {
    title: '编号',
    dataIndex: 'code',
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
  },
  {
    title: '邮箱',
    dataIndex: 'email',
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
import http from '../../../http'
export default {
  data() {
    return {
      treeData: [],
      checkedKeys: [],
      column,
      columns,
      title: '',
      visible: false,
      visibles: false,
      logvisible: false,
      placement: 'right',
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      current: '',
      params: {
        current: 1,
        pageSize: 10,
      },
      value: 'name',
      key: '',
      total: 0,
      totals: 0,
      positionlist: [],
      Userlist: [],
      userlist: [],
      userId: '',
      selectedRowKey: [],
      Id: '',
      form: {
        id: '',
        architectureId: '',
        code: '',
        name: '',
        state: '',
        superiorId: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: '名称长度为2-16',
            trigger: 'blur',
            min: 2,
            max: 16,
          },
        ],
        code: [
          {
            required: true,
            message: '编号长度为2-16个字符',
            trigger: 'blur',
            min: 2,
            max: 16,
          },
        ],
      },
    }
  },
  filters: {},
  watch: {
    checkedKeys(val) {
      //   console.log("onCheck11", val);
      this.checkedKeys = val
    },
  },
  methods: {
    onSearch(value) {
      this.params = {
        current: 1,
        pageSize: 10,
      }
      this.params[this.value] = {
        value: value,
        op: '%like%',
      }
      if (value == 'state') {
        this.params[this.value].op = '='
      }
      this.key = ''
      this.getPosition()
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    onClose() {
      this.visible = false
      this.params.current = 1
      this.visibles = false
      this.form.architectureId = ''
      this.form.code = ''
      this.form.id = ''
      this.form.name = ''
      this.form.state = ''
      this.form.superiorId = ''
      this.Id = ''
    },
    onDetele() {
      let that = this
      this.$confirm({
        title: '确认要删除该角色么?',
        okText: '是',
        cancelText: '否',
        onOk() {
          setTimeout(() => {
            that.deletePosition()
          }, 100)
        },
        onCancel() {
          console.log()
        },
      })
    },
    onEdit(item) {
      this.form.name = item.name
      this.Id = item.id
      this.visible = true
      this.title = '编辑'
      this.current = 1
      this.gainPositionmodule()
      this.getpositionuser()
      this.getuserlist()
      //   this.getPositionId(item.id);
    },
    thisEdit(item) {
      this.Id = item.id
      this.form.id = item.id
      this.visibles = true
      this.title = '编辑'
      this.getPositionId(item.id)
    },
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.statePosition(e.id, this.form.state)
    },
    onpagesize(e) {
      this.params = {
        current: e,
        pageSize: 10,
      }
      this.current = e
      this.getPosition()
      // console.log(this.params.current);
    },
    onpagesizeuser(e) {
      this.params.current = e
      this.getpositionuser()
      // console.log(this.params.current);
    },
    showDrawer() {
      this.visibles = true
      this.title = '新增'
    },
    async getPosition() {
      try {
        const res = await http.getPosition(this.params)
        // console.log(res);
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.positionlist = data.positions.records
          this.totals = data.positions.total
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    onSelect(e) {
      console.log(e)
    },
    async getPositionId(id) {
      try {
        const res = await http.getPositionId(id)
        // console.log(res);
        const { success, data } = res.data
        if (success) {
          this.form.architectureId = data.position.architectureId
          this.form.code = data.position.code
          this.form.name = data.position.name
          this.form.superiorId = data.position.superiorId
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async putPosition(form) {
      try {
        const res = await http.putPosition(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.onClose()
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async AddPosition(form) {
      try {
        const res = await http.AddPosition(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.onClose()
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    // 删除
    async deletePosition() {
      try {
        const res = await http.deletePosition(this.selectedRowKey)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    async statePosition(id, state) {
      try {
        const res = await http.statePosition(id, state)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },

    handleOk() {
      if (this.visible == true) {
        this.getInvitpositionuser(this.userId)
        this.visibles = false
      } else {
        // console.log("submit!", this.form);
        if (this.Id == '') {
          this.AddPosition(this.form)
        } else if (this.Id != '') {
          this.putPosition(this.form)
        }
        this.visibles = false
      }
    },
    onSavekey() {
      this.gainPositionmoduleId()
    },
    //角色权限管理
    async getuserlist() {
      try {
        const res = await http.getuserlist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.Userlist = data.users
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async gainPositionmodule() {
      try {
        const res = await http.gainPositionmodule(this.Id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.treeData = data.modules
          this.checkedKeys = data.checkedModules
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async gainPositionmoduleId() {
      try {
        const res = await http.gainPositionmoduleId(this.Id, this.checkedKeys)
        this.gainPositionmodule()
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.onClose()
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    //员工
    async getpositionuser() {
      try {
        const res = await http.getpositionuser(
          this.current,
          this.params.pageSize,
          this.Id
        )
        const { success, data } = res.data
        if (success) {
          this.userlist = data.users.records
          this.total = data.users.total
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getInvitpositionuser(id) {
      try {
        const res = await http.getInvitpositionuser(this.Id, id)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
          this.getpositionuser()
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    ondetailuser(e) {
      this.delpositionuser(e.id)
    },
    async delpositionuser(id) {
      try {
        const res = await http.delpositionuser(this.Id, id)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
          this.getpositionuser()
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getPosition()
      }, 500)
    },
    onout() {
      this.$router.push({ name: 'login' })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
  mounted() {
    this.getPosition()
    const users = JSON.parse(localStorage.getItem('store-userlist'))
    if (users == null) {
      this.logvisible = true
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
  overflow-y: hidden;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 3px;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  margin-bottom: 15px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
.edit-title {
  height: 50px;
  background-color: #edeef0;
  line-height: 50px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 500;
}
.edit-main {
  .edit-main-name {
    height: 35px;
    line-height: 35px;
  }
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  .edit-main-top {
    display: flex;
    flex-direction: column;
  }
  .edit-main-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .main-bottom-main {
    padding-bottom: 50px;
    position: relative;
    height: 500px;
    border: 2px solid #dddddd;
    .ant-tree {
      height: 450px;
      overflow-y: auto;
    }
    .main-bottom-btn {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50px;
      text-align: center;
      line-height: 50px;
    }
  }
}
</style>
